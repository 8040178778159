import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"

import { useLocation } from "@reach/router"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Box from "@material-ui/core/Box"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import MenuItem from "@material-ui/core/MenuItem"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

import { GatsbyImage } from "gatsby-plugin-image"

import { colors } from "./colors"
import { maxWidth } from "./maxwidth"

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: "#fff",
    height: "90px",

    "& .MuiToolbar-root": {
      justifyContent: "center",
      height: "100%",
    },
  },
  logo: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    height: "100%",
    width: "20rem",
    minWidth: "200px",
  },
  tabs: {
    color: "black",

    "& .MuiTab-root": {
      minWidth: "0",
    },

    "& .MuiTabs-fixed": {
      alignItems: "center",
      display: "flex",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: colors.azul,
      top: 0,
    },

    "& .Mui-selected": {
      color: colors.azul,
    },
  },
  modalidadesButton: {
    display: "flex",
    fontSize: "0.875rem",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  menuIcon: {
    color: colors.azul,
  },
  drawer: {
    "&.MuiDrawer-root": {
      width: "250px",
      zIndex: 0,
    },

    "& .MuiDrawer-paper": {
      backgroundColor: colors.black,
      width: "250px",
    },

    "& .MuiMenuItem-root": {
      color: colors.azul,

      "&:hover": {
        backgroundColor: "rgba(0,0,0, 0.2)",
      },
    },
  },
}))

const Header = props => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const location = useLocation()

  const [currentTab, setCurrentTab] = useState(0)
  const [modRef, setModRef] = useState(null)
  const [drawerOpen, setDraweOpen] = useState(false)
  const open = Boolean(modRef)

  const classes = useStyles({ ref: modRef })

  const pages = [
    "/",
    "/escritorio",
    "/atuacao",
    "/advogados",
    "/localizacao",
    "/contato",
  ]

  useEffect(() => {
    if (mdUp) setDraweOpen(false)
  }, [mdUp])

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.replace(/^\//g, "").replace(/\/\w*$/g, "")

      let index = 0
      switch (path) {
        case "escritorio":
          index = 1
          break
        case "atuacao":
          index = 2
          break
        case "advogados":
          index = 3
          break
        case "localizacao":
          index = 4
          break
        case "contato":
          index = 5
          break
        default:
          index = 0
      }

      setCurrentTab(index)
    }
  }, [location.pathname])

  const handleChangeTab = (e, value) => {
    const target = e.currentTarget
    // if (value === 2) {
    //   setModRef(target)
    //   return
    // }

    if (value !== currentTab) navigate(pages[value])
  }

  const handleClose = () => {
    setModRef(null)
  }

  const categorySelected = id => () => {
    navigate(`/${id}`)
  }

  const toggleDrawer = () => {
    setDraweOpen(_old => !_old)
  }

  const drawerClose = () => {
    setDraweOpen(false)
  }

  return (
    <>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <Box
            width={1}
            height={1}
            display="flex"
            maxWidth={maxWidth}
            px={{ xs: "10px", sm: "20px" }}
            alignItems="center"
          >
            <Box className={classes.logo} onClick={() => navigate("/")}>
              <GatsbyImage
                image={data.logo.childImageSharp.gatsbyImageData}
                alt="grupo equilíbrio ambiental"
                objectFit="contain"
              />
            </Box>
            <Hidden smDown>
              <Box
                width={1}
                display="flex"
                justifyContent="flex-end"
                height={0.8}
              >
                <Tabs
                  value={currentTab}
                  className={classes.tabs}
                  onChange={handleChangeTab}
                >
                  <Tab label="home" value={0} />
                  <Tab label="o escritório" value={1} />
                  <Tab label="atuação" value={2} />
                  <Tab label="advogados" value={3} />
                  <Tab label="localização" value={4} />
                  <Tab label="contato" value={5} />
                </Tabs>
              </Box>
              <Box display="flex" ml="20px">
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.instagram.com/gomesassociados.adv/"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.facebook.com/gomesassociados.adv"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.linkedin.com/company/gomes-associados-advocacia/"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box ml="auto">
                <IconButton className={classes.menuIcon} onClick={toggleDrawer}>
                  {!drawerOpen ? <MenuIcon /> : <CloseIcon />}
                </IconButton>
              </Box>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        className={classes.drawer}
        onClose={drawerClose}
      >
        <Box height="100px" width={1} mb="20px"></Box>
        <MenuItem onClick={categorySelected("")}>Home</MenuItem>
        <MenuItem onClick={categorySelected("escritorio")}>
          O Escritório
        </MenuItem>
        <MenuItem onClick={categorySelected("seguranca_do_trabalho")}>
          Atuação
        </MenuItem>
        <MenuItem onClick={categorySelected("meio_ambiente")}>
          Advogados
        </MenuItem>
        <MenuItem onClick={categorySelected("contato")}>Localização</MenuItem>
        <MenuItem onClick={categorySelected("contato")}>Contato</MenuItem>
        <Box display="flex" ml="20px">
          <IconButton
            component="a"
            target="_blank"
            href="https://www.instagram.com/gomesassociados.adv/"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://www.facebook.com/gomesassociados.adv"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://www.linkedin.com/company/gomes-associados-advocacia/"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default Header
