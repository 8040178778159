import React, { useEffect, useState } from "react"

import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import WhatsAppIcon from "@material-ui/icons/WhatsApp"

import Header from "./header"

const desktops = ["macintosh", "windows", "linux"]

let isDesk

if (typeof window !== "undefined") {
  const deviceDetect = require("device-detect")
  isDesk = desktops.includes(deviceDetect().device.toLowerCase())
}

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body": {
      backgroundColor: "white",
      fontFamily: "'Open Sans', sans-serif",
    },

    ".MuiTypography-root": {
      fontFamily: "'Open Sans', sans-serif",
    },
  },
  whats: {
    position: "fixed",
    backgroundColor: "#2CB742",
    zIndex: 100000,
    right: "20px",
    bottom: "40px",
    borderRadius: "50%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#2CB742",
    },

    "& svg": {
      width: "40px",
      height: "40px",
      fill: "#fff",
    },
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()

  const [isDesktop, setIsDesktop] = useState("")

  useEffect(() => {
    setIsDesktop(
      `https://${isDesk ? "web" : "api"}.whatsapp.com/send?phone=5511971228295`
    )
  }, [isDesk])

  return (
    <>
      <CssBaseline />
      <Header />
      <Box width="100%" height="100%" overflow="hidden" component="main">
        {children}
      </Box>
      <Button
        component="a"
        href={isDesktop}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.whats}
      >
        <WhatsAppIcon />
      </Button>
    </>
  )
}

export default Layout
